import React, { useEffect, useState } from 'react';
import './navbar.css';
import mtgoxlogo from '../assets/navw.png';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-left">
        {/* Logo */}
        <img src={mtgoxlogo} alt="Logo" className="logo" />
      </div>
      <div className="navbar-right">
        {/* Menu items */}
        <ul className="menu">
          <li><a href="#about" rel="noopener noreferrer">About</a></li>
          <li><a href="#tax" rel="noopener noreferrer">Tax</a></li>
          <li><a href="https://dexscreener.com/base/0xd8713809408355D6202A95bE076c2EeddcB74a5B" target="_blank" rel="noopener noreferrer">Dexscreener</a></li>
        </ul>
        {/* Buy button */}
        <button className="buy-button"><a href="https://www.sushi.com/swap?chainId=8453&token1=0xd8713809408355D6202A95bE076c2EeddcB74a5B" target="_blank" rel="noopener noreferrer" className="buy-link">Buy</a></button>
      </div>
    </nav>
  );
}

export default Navbar;
