import React from 'react';
import video from '../assets/hero1.mp4';
import banner from '../assets/banner.png';
import twitterIcon from '../assets/twitter.svg';
import telegramIcon from '../assets/telegram.svg';

import 'animate.css';
import './hero.css';

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1 className="header animate__animated animate__fadeInDown">JOIN THE</h1>
        <img src={banner} alt="Banner" className="banner animate__animated animate__fadeIn" />
        <div className="icon-container animate__animated animate__fadeInUp">
        <a href="https://twitter.com/baldworld_base" target="_blank" rel="noopener noreferrer"><img src={twitterIcon} alt="Twitter" className="icon animate__animated animate__zoomIn" /></a>
        <a href="https://t.me/baldworldportal" target="_blank" rel="noopener noreferrer"><img src={telegramIcon} alt="Telegram" className="icon animate__animated animate__zoomIn" /></a>
        </div>
      </div>
      <video className="hero-background" autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default Hero;
