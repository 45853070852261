// Footer.jsx

import React from 'react';
import './footer.css';
import about1 from '../assets/FOOTER.png'; 

const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo">
        <img src={about1} alt="Logo" />
      </div>
      <div className="text">
    <p>&copy; {new Date().getFullYear()} BALDWORLD</p>

      </div>
    </footer>
  );
}

export default Footer;
