import React from 'react';
import Navbar from './components/navbar.js';
import Hero from './components/hero.js';
import About from './components/about.js';
import Tokenomics from './components/tokenomics.js';
import Footer from './components/footer.js';
function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Tokenomics />
      <Footer />
    </div>
  );
}

export default App;