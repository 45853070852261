// Tokenomic.jsx

import React from 'react';
import './tokenomics.css';

const Tokenomic = () => {
  return (
    <div className="container" id="tax">

    </div>
  );
}

export default Tokenomic;
