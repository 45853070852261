import React from 'react';
import Card from './card.js';
import './about.css';
import MtGoxLogo from '../assets/aboutleft.png'; // Import the SVG image file
import about from '../assets/about.png'; // Import the SVG image file
import about1 from '../assets/aboutright.png'; 

const handleCopyToClipboard = (walletAddress) => {
  navigator.clipboard.writeText(walletAddress)
    .then(() => {
      alert('Token address copied to clipboard: ' + walletAddress);
    })
    .catch((error) => {
      console.error('Failed to copy wallet address to clipboard:', error);
    });
};

const About = () => {
  const walletAddress = '0xd8713809408355D6202A95bE076c2EeddcB74a5B';

  return (
    <div>
      <div className="about-section" id="about">
        <div className="about-content">
          <div className="about-left">
            <img src={MtGoxLogo} alt="MTGOX Logo" /> {/* Replace text with SVG image */}
          </div>
          <div className="about-right">
            <Card>
              <img src={about} alt="about" /> <img src={about1} alt="about" /> 
              <p onClick={() => handleCopyToClipboard(walletAddress)}>CA:
                <b>{walletAddress}</b>
              </p>
              <p>
              Baldworld, the land of the fiercely challenged! This original meme coin promises to be a satire of the well-known Palworld concept and is to be launch on the Base chain. Instead of adorable animals to trade and collect, Baldworld is home to a cast of bald characters, one of whom is none other than Brian Armstrong!
                <br /><br />
                
              </p>
            </Card>
          </div>
        </div>
      </div>
      <div className="about-section"></div>
    </div>
  );
};

export default About;
